import * as React from 'react';
import { MeQuery } from '../../generated/graphql';
import './styles.css';

interface Props {
  data: MeQuery;
}

const className = 'Me';

const Me: React.FC<Props> = ({ data }) => {
  const [email, setEmail] = React.useState(data.me!.email);
  // const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState(data.me!.name);

  // function checkIfFormValid () {
  //   return(email !== "" && password !== "" && name !== "");
  // }

  return (
    <div className={className}>
      <h3>Me</h3>
      <form onSubmit={e => {
        e.preventDefault();
        // mutation({ variables: {
        //     email: email,
        //     password: password,
        //     name: name,
        //   }
        // });
      }}>
        <input className={`${className}`} type="text" id="name" placeholder="Your name" disabled={true} value={name} onChange={e => {
          setName(e.target.value);
        }}></input>

        <br/>

        <input className={`${className}`} type="text" id="email" placeholder="Your email address" disabled={true} value={email} onChange={e => {
          setEmail(e.target.value);
        }}></input>

        {/* <input className={`${className}`} type="password" id="password" placeholder="Your password" onChange={e => {
          setPassword(e.target.value);
        }}></input> */}
        
        {/* <input type="submit" disabled={!checkIfFormValid()}></input> */}
      </form>
    </div>
  )
};

export default Me;
