import * as React from 'react';
import { usePlaceListQuery } from '../../generated/graphql';
import PlaceList from './PlaceList';

const PlaceListContainer = () => {
  const { data, error, loading } = usePlaceListQuery();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !data) {
    return <div>Failure: {String(error)}</div>;
  }

  return <PlaceList data={data} />;
};

export default PlaceListContainer;
