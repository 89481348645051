import * as React from 'react';
import { useLoginMutation } from '../../generated/graphql';
import Login from './Login';
import * as Constants from '../../constants';

const LoginContainer = () => {
  const [loginFn, { data, error, loading }] = useLoginMutation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Failure: {String(error)}</div>;
  }

  if (data) {
    localStorage.setItem(Constants.userTokenLSKey, data.login.token);
    return <div>
      You were logged in successfully, you can now <a href="/">use the app</a>!
      </div>;
  }

  return <Login loginFn={loginFn} data={data}/>;
};

export default LoginContainer;
