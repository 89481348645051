import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
};

export type Apartment = {
   __typename?: 'Apartment',
  id: Scalars['ID'],
  user?: Maybe<User>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  places: Array<Place>,
  tenants?: Maybe<Array<Tenant>>,
};

export type AuthPayload = {
   __typename?: 'AuthPayload',
  token: Scalars['String'],
  user: User,
};


export type Mutation = {
   __typename?: 'Mutation',
  signup: AuthPayload,
  login: AuthPayload,
  createApartment: Apartment,
  updateApartment: Apartment,
  deleteApartment?: Maybe<Apartment>,
  createPlace: Place,
  updatePlace: Place,
  deletePlace?: Maybe<Place>,
  createPlaceCleaning: PlaceCleaning,
};


export type MutationSignupArgs = {
  email: Scalars['String'],
  password: Scalars['String'],
  name: Scalars['String']
};


export type MutationLoginArgs = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type MutationCreateApartmentArgs = {
  name: Scalars['String'],
  image?: Maybe<Scalars['String']>
};


export type MutationUpdateApartmentArgs = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  placeIds?: Maybe<Array<Scalars['String']>>
};


export type MutationDeleteApartmentArgs = {
  id: Scalars['ID']
};


export type MutationCreatePlaceArgs = {
  apartmentId: Scalars['ID'],
  name: Scalars['String'],
  difficulty: Scalars['Int']
};


export type MutationUpdatePlaceArgs = {
  id: Scalars['ID'],
  name?: Maybe<Scalars['String']>,
  difficulty?: Maybe<Scalars['Int']>
};


export type MutationDeletePlaceArgs = {
  id: Scalars['ID']
};


export type MutationCreatePlaceCleaningArgs = {
  placeId: Scalars['ID'],
  cleanedAt: Scalars['DateTime']
};

export type Place = {
   __typename?: 'Place',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name: Scalars['String'],
  difficulty: Scalars['Int'],
  apartment: Apartment,
  placeCleanings?: Maybe<Array<PlaceCleaning>>,
};

export type PlaceCleaning = {
   __typename?: 'PlaceCleaning',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  place: Place,
  cleanedAt: Scalars['DateTime'],
};

export type Query = {
   __typename?: 'Query',
  me?: Maybe<User>,
  apartments: Array<Maybe<Apartment>>,
  places: Array<Maybe<Place>>,
  placeCleanings: Array<Maybe<PlaceCleaning>>,
};

export type Tenant = {
   __typename?: 'Tenant',
  id: Scalars['ID'],
  user: User,
  apartment: Apartment,
};

export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  email: Scalars['String'],
  name: Scalars['String'],
  apartments: Array<Apartment>,
};

export type ApartmentListQueryVariables = {};


export type ApartmentListQuery = (
  { __typename?: 'Query' }
  & { apartments: Array<Maybe<(
    { __typename?: 'Apartment' }
    & Pick<Apartment, 'id' | 'name'>
    & { places: Array<(
      { __typename?: 'Place' }
      & Pick<Place, 'name'>
    )>, user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )>> }
);

export type CreateApartmentMutationVariables = {
  name: Scalars['String'],
  image?: Maybe<Scalars['String']>
};


export type CreateApartmentMutation = (
  { __typename?: 'Mutation' }
  & { createApartment: (
    { __typename?: 'Apartment' }
    & Pick<Apartment, 'id' | 'name'>
  ) }
);

export type LoginMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String']
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
  ) }
);

export type MeQueryVariables = {};


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name'>
    & { apartments: Array<(
      { __typename?: 'Apartment' }
      & Pick<Apartment, 'name'>
      & { places: Array<(
        { __typename?: 'Place' }
        & Pick<Place, 'name'>
      )> }
    )> }
  )> }
);

export type PlaceCleaningListQueryVariables = {};


export type PlaceCleaningListQuery = (
  { __typename?: 'Query' }
  & { placeCleanings: Array<Maybe<(
    { __typename?: 'PlaceCleaning' }
    & Pick<PlaceCleaning, 'id' | 'cleanedAt'>
    & { place: (
      { __typename?: 'Place' }
      & Pick<Place, 'id' | 'name'>
    ) }
  )>> }
);

export type CreatePlaceCleaningMutationVariables = {
  placeId: Scalars['ID'],
  cleanedAt: Scalars['DateTime']
};


export type CreatePlaceCleaningMutation = (
  { __typename?: 'Mutation' }
  & { createPlaceCleaning: (
    { __typename?: 'PlaceCleaning' }
    & Pick<PlaceCleaning, 'id' | 'cleanedAt'>
  ) }
);

export type PlaceListQueryVariables = {};


export type PlaceListQuery = (
  { __typename?: 'Query' }
  & { places: Array<Maybe<(
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name'>
    & { apartment: (
      { __typename?: 'Apartment' }
      & Pick<Apartment, 'id' | 'name'>
    ) }
  )>> }
);

export type SignupMutationVariables = {
  email: Scalars['String'],
  password: Scalars['String'],
  name: Scalars['String']
};


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
  ) }
);


export const ApartmentListDocument = gql`
    query ApartmentList {
  apartments {
    id
    name
    places {
      name
    }
    user {
      id
    }
  }
}
    `;
export type ApartmentListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApartmentListQuery, ApartmentListQueryVariables>, 'query'>;

    export const ApartmentListComponent = (props: ApartmentListComponentProps) => (
      <ApolloReactComponents.Query<ApartmentListQuery, ApartmentListQueryVariables> query={ApartmentListDocument} {...props} />
    );
    
export type ApartmentListProps<TChildProps = {}> = ApolloReactHoc.DataProps<ApartmentListQuery, ApartmentListQueryVariables> & TChildProps;
export function withApartmentList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ApartmentListQuery,
  ApartmentListQueryVariables,
  ApartmentListProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ApartmentListQuery, ApartmentListQueryVariables, ApartmentListProps<TChildProps>>(ApartmentListDocument, {
      alias: 'apartmentList',
      ...operationOptions
    });
};

/**
 * __useApartmentListQuery__
 *
 * To run a query within a React component, call `useApartmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApartmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApartmentListQuery({
 *   variables: {
 *   },
 * });
 */
export function useApartmentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApartmentListQuery, ApartmentListQueryVariables>) {
        return ApolloReactHooks.useQuery<ApartmentListQuery, ApartmentListQueryVariables>(ApartmentListDocument, baseOptions);
      }
export function useApartmentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApartmentListQuery, ApartmentListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApartmentListQuery, ApartmentListQueryVariables>(ApartmentListDocument, baseOptions);
        }
export type ApartmentListQueryHookResult = ReturnType<typeof useApartmentListQuery>;
export type ApartmentListLazyQueryHookResult = ReturnType<typeof useApartmentListLazyQuery>;
export type ApartmentListQueryResult = ApolloReactCommon.QueryResult<ApartmentListQuery, ApartmentListQueryVariables>;
export const CreateApartmentDocument = gql`
    mutation CreateApartment($name: String!, $image: String) {
  createApartment(name: $name, image: $image) {
    id
    name
  }
}
    `;
export type CreateApartmentMutationFn = ApolloReactCommon.MutationFunction<CreateApartmentMutation, CreateApartmentMutationVariables>;
export type CreateApartmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateApartmentMutation, CreateApartmentMutationVariables>, 'mutation'>;

    export const CreateApartmentComponent = (props: CreateApartmentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateApartmentMutation, CreateApartmentMutationVariables> mutation={CreateApartmentDocument} {...props} />
    );
    
export type CreateApartmentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateApartmentMutation, CreateApartmentMutationVariables> & TChildProps;
export function withCreateApartment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateApartmentMutation,
  CreateApartmentMutationVariables,
  CreateApartmentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateApartmentMutation, CreateApartmentMutationVariables, CreateApartmentProps<TChildProps>>(CreateApartmentDocument, {
      alias: 'createApartment',
      ...operationOptions
    });
};

/**
 * __useCreateApartmentMutation__
 *
 * To run a mutation, you first call `useCreateApartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApartmentMutation, { data, loading, error }] = useCreateApartmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useCreateApartmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApartmentMutation, CreateApartmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApartmentMutation, CreateApartmentMutationVariables>(CreateApartmentDocument, baseOptions);
      }
export type CreateApartmentMutationHookResult = ReturnType<typeof useCreateApartmentMutation>;
export type CreateApartmentMutationResult = ApolloReactCommon.MutationResult<CreateApartmentMutation>;
export type CreateApartmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApartmentMutation, CreateApartmentMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type LoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LoginMutation, LoginMutationVariables> & TChildProps;
export function withLogin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutation,
  LoginMutationVariables,
  LoginProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutation, LoginMutationVariables, LoginProps<TChildProps>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
    name
    apartments {
      name
      places {
        name
      }
    }
  }
}
    `;
export type MeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MeQuery, MeQueryVariables>, 'query'>;

    export const MeComponent = (props: MeComponentProps) => (
      <ApolloReactComponents.Query<MeQuery, MeQueryVariables> query={MeDocument} {...props} />
    );
    
export type MeProps<TChildProps = {}> = ApolloReactHoc.DataProps<MeQuery, MeQueryVariables> & TChildProps;
export function withMe<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MeQuery,
  MeQueryVariables,
  MeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, MeQuery, MeQueryVariables, MeProps<TChildProps>>(MeDocument, {
      alias: 'me',
      ...operationOptions
    });
};

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const PlaceCleaningListDocument = gql`
    query PlaceCleaningList {
  placeCleanings {
    id
    cleanedAt
    place {
      id
      name
    }
  }
}
    `;
export type PlaceCleaningListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlaceCleaningListQuery, PlaceCleaningListQueryVariables>, 'query'>;

    export const PlaceCleaningListComponent = (props: PlaceCleaningListComponentProps) => (
      <ApolloReactComponents.Query<PlaceCleaningListQuery, PlaceCleaningListQueryVariables> query={PlaceCleaningListDocument} {...props} />
    );
    
export type PlaceCleaningListProps<TChildProps = {}> = ApolloReactHoc.DataProps<PlaceCleaningListQuery, PlaceCleaningListQueryVariables> & TChildProps;
export function withPlaceCleaningList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PlaceCleaningListQuery,
  PlaceCleaningListQueryVariables,
  PlaceCleaningListProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PlaceCleaningListQuery, PlaceCleaningListQueryVariables, PlaceCleaningListProps<TChildProps>>(PlaceCleaningListDocument, {
      alias: 'placeCleaningList',
      ...operationOptions
    });
};

/**
 * __usePlaceCleaningListQuery__
 *
 * To run a query within a React component, call `usePlaceCleaningListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceCleaningListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceCleaningListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlaceCleaningListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlaceCleaningListQuery, PlaceCleaningListQueryVariables>) {
        return ApolloReactHooks.useQuery<PlaceCleaningListQuery, PlaceCleaningListQueryVariables>(PlaceCleaningListDocument, baseOptions);
      }
export function usePlaceCleaningListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlaceCleaningListQuery, PlaceCleaningListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlaceCleaningListQuery, PlaceCleaningListQueryVariables>(PlaceCleaningListDocument, baseOptions);
        }
export type PlaceCleaningListQueryHookResult = ReturnType<typeof usePlaceCleaningListQuery>;
export type PlaceCleaningListLazyQueryHookResult = ReturnType<typeof usePlaceCleaningListLazyQuery>;
export type PlaceCleaningListQueryResult = ApolloReactCommon.QueryResult<PlaceCleaningListQuery, PlaceCleaningListQueryVariables>;
export const CreatePlaceCleaningDocument = gql`
    mutation CreatePlaceCleaning($placeId: ID!, $cleanedAt: DateTime!) {
  createPlaceCleaning(placeId: $placeId, cleanedAt: $cleanedAt) {
    id
    cleanedAt
  }
}
    `;
export type CreatePlaceCleaningMutationFn = ApolloReactCommon.MutationFunction<CreatePlaceCleaningMutation, CreatePlaceCleaningMutationVariables>;
export type CreatePlaceCleaningComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreatePlaceCleaningMutation, CreatePlaceCleaningMutationVariables>, 'mutation'>;

    export const CreatePlaceCleaningComponent = (props: CreatePlaceCleaningComponentProps) => (
      <ApolloReactComponents.Mutation<CreatePlaceCleaningMutation, CreatePlaceCleaningMutationVariables> mutation={CreatePlaceCleaningDocument} {...props} />
    );
    
export type CreatePlaceCleaningProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreatePlaceCleaningMutation, CreatePlaceCleaningMutationVariables> & TChildProps;
export function withCreatePlaceCleaning<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreatePlaceCleaningMutation,
  CreatePlaceCleaningMutationVariables,
  CreatePlaceCleaningProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreatePlaceCleaningMutation, CreatePlaceCleaningMutationVariables, CreatePlaceCleaningProps<TChildProps>>(CreatePlaceCleaningDocument, {
      alias: 'createPlaceCleaning',
      ...operationOptions
    });
};

/**
 * __useCreatePlaceCleaningMutation__
 *
 * To run a mutation, you first call `useCreatePlaceCleaningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceCleaningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceCleaningMutation, { data, loading, error }] = useCreatePlaceCleaningMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      cleanedAt: // value for 'cleanedAt'
 *   },
 * });
 */
export function useCreatePlaceCleaningMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlaceCleaningMutation, CreatePlaceCleaningMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlaceCleaningMutation, CreatePlaceCleaningMutationVariables>(CreatePlaceCleaningDocument, baseOptions);
      }
export type CreatePlaceCleaningMutationHookResult = ReturnType<typeof useCreatePlaceCleaningMutation>;
export type CreatePlaceCleaningMutationResult = ApolloReactCommon.MutationResult<CreatePlaceCleaningMutation>;
export type CreatePlaceCleaningMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlaceCleaningMutation, CreatePlaceCleaningMutationVariables>;
export const PlaceListDocument = gql`
    query PlaceList {
  places {
    id
    name
    apartment {
      id
      name
    }
  }
}
    `;
export type PlaceListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlaceListQuery, PlaceListQueryVariables>, 'query'>;

    export const PlaceListComponent = (props: PlaceListComponentProps) => (
      <ApolloReactComponents.Query<PlaceListQuery, PlaceListQueryVariables> query={PlaceListDocument} {...props} />
    );
    
export type PlaceListProps<TChildProps = {}> = ApolloReactHoc.DataProps<PlaceListQuery, PlaceListQueryVariables> & TChildProps;
export function withPlaceList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PlaceListQuery,
  PlaceListQueryVariables,
  PlaceListProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PlaceListQuery, PlaceListQueryVariables, PlaceListProps<TChildProps>>(PlaceListDocument, {
      alias: 'placeList',
      ...operationOptions
    });
};

/**
 * __usePlaceListQuery__
 *
 * To run a query within a React component, call `usePlaceListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaceListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaceListQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlaceListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlaceListQuery, PlaceListQueryVariables>) {
        return ApolloReactHooks.useQuery<PlaceListQuery, PlaceListQueryVariables>(PlaceListDocument, baseOptions);
      }
export function usePlaceListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlaceListQuery, PlaceListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlaceListQuery, PlaceListQueryVariables>(PlaceListDocument, baseOptions);
        }
export type PlaceListQueryHookResult = ReturnType<typeof usePlaceListQuery>;
export type PlaceListLazyQueryHookResult = ReturnType<typeof usePlaceListLazyQuery>;
export type PlaceListQueryResult = ApolloReactCommon.QueryResult<PlaceListQuery, PlaceListQueryVariables>;
export const SignupDocument = gql`
    mutation Signup($email: String!, $password: String!, $name: String!) {
  signup(email: $email, password: $password, name: $name) {
    token
  }
}
    `;
export type SignupMutationFn = ApolloReactCommon.MutationFunction<SignupMutation, SignupMutationVariables>;
export type SignupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SignupMutation, SignupMutationVariables>, 'mutation'>;

    export const SignupComponent = (props: SignupComponentProps) => (
      <ApolloReactComponents.Mutation<SignupMutation, SignupMutationVariables> mutation={SignupDocument} {...props} />
    );
    
export type SignupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SignupMutation, SignupMutationVariables> & TChildProps;
export function withSignup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SignupMutation,
  SignupMutationVariables,
  SignupProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SignupMutation, SignupMutationVariables, SignupProps<TChildProps>>(SignupDocument, {
      alias: 'signup',
      ...operationOptions
    });
};

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, baseOptions);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = ApolloReactCommon.MutationResult<SignupMutation>;
export type SignupMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupMutation, SignupMutationVariables>;