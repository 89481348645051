import * as React from 'react';
import { useApartmentListQuery, useCreateApartmentMutation } from '../../generated/graphql';
import ApartmentList from './ApartmentList';

const ApartmentListContainer = () => {
  const apartmentListQueryInfo = useApartmentListQuery();
  const [createApartmentFn, createApartmentMutationInfo] = useCreateApartmentMutation();

  if (apartmentListQueryInfo.loading) {
    return <div>Loading...</div>;
  }

  if (apartmentListQueryInfo.error || !apartmentListQueryInfo.data) {
    return <div>Failure: {String(apartmentListQueryInfo.error)}</div>;
  }

  return <ApartmentList data={apartmentListQueryInfo.data} createApartmentInfo={createApartmentMutationInfo} createApartmentFn={createApartmentFn} />;
};

export default ApartmentListContainer;
