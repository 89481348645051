import * as React from 'react';
import { useMeQuery } from '../../generated/graphql';
import Me from './Me';

const MeContainer = () => {
  const { data, error, loading } = useMeQuery();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Failure: {String(error)}</div>;
  }

  if (!data || !data.me) {
    return <div>No user data</div>
  }

  return <Me data={data}/>;
};

export default MeContainer;
