import * as React from 'react';
import { LoginMutation, LoginMutationFn } from '../../generated/graphql';
import './styles.css';

interface Props {
  loginFn: LoginMutationFn;
  data?: LoginMutation;
}

const className = 'Login';

const Login: React.FC<Props> = ({ loginFn, data }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  function checkIfFormValid () {
    return(email !== "" && password !== "");
  }

  return (
    <div className={className}>
      <h3>Login</h3>
      <form onSubmit={e => {
        e.preventDefault();
        loginFn({ variables: {
            email: email,
            password: password,
          }
        });
      }}>
        <input className={`${className}`} type="text" id="email" placeholder="Your email address" onChange={e => {
          setEmail(e.target.value);
        }}></input>

        <input className={`${className}`} type="password" id="password" placeholder="Your password" onChange={e => {
          setPassword(e.target.value);
        }}></input>

        <input type="submit" disabled={!checkIfFormValid()}></input>
      </form>
    </div>
  )
};

export default Login;
