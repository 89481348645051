import * as React from 'react';
import { ApartmentListQuery, CreateApartmentMutationFn, CreateApartmentMutationResult } from '../../generated/graphql';
import './styles.css';

interface Props {
  data: ApartmentListQuery;
  createApartmentInfo: CreateApartmentMutationResult;
  createApartmentFn: CreateApartmentMutationFn;
}

const className = 'ApartmentList';

const ApartmentList: React.FC<Props> = ({ data, createApartmentInfo, createApartmentFn }) => (
  <div className={className}>
    <h3>My Apartments</h3>
    <ol className={`${className}__list`}>
      {!!data.apartments &&
        data.apartments.map(
          (apartment, i) =>
            !!apartment && (
              <li key={i} className={`${className}__item`}>
                {apartment.name}: {String(apartment.places.length)} places found.
              </li>
            ),
        )}
    </ol>
    <button onClick={event => {createApartmentFn({
      variables: {
        name: "My new apartment",
      }})}}>Add new apartment</button>
    <div>
      {!!createApartmentInfo.data && 
        "Apartment created with following name: " + createApartmentInfo.data.createApartment.name
      }
    </div>
  </div>
);

export default ApartmentList;
