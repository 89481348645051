import React from 'react';
import ReactDOM from 'react-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Constants from './constants';

const localhostDevMode = window.location.hostname.includes("localhost");

var serverUri;
if (localhostDevMode) {
    console.log("Running in localhost dev mode");
    serverUri = 'http://o.local:4000';
} else {
    serverUri = 'https://fairly-clean-api-oymmxabrwi.now.sh';
}

const client = new ApolloClient({
    uri: serverUri,
    request: (operation) => {
        const token = localStorage.getItem(Constants.userTokenLSKey);
        operation.setContext( {
            headers: {
                authorization: token ? `Bearer ${token}` : '',
            }
        });
    }
});

ReactDOM.render(
<ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
    <App />
    </ApolloHooksProvider>
</ApolloProvider>,
document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
