import * as React from 'react';
import { PlaceListQuery } from '../../generated/graphql';
import './styles.css';

interface Props {
  data: PlaceListQuery;
}

const className = 'PlaceList';

const PlaceList: React.FC<Props> = ({ data }) => (
  <div className={className}>
    <h3>My Places</h3>
    <ul className={`${className}__list`}>
      {!!data.places &&
        data.places.map(
          (place, i) =>
            !!place && (
              <li key={i} className={`${className}__item`}>
                {place.name} in {String(place.apartment.name)}
              </li>
            ),
        )}
    </ul>
    <button disabled={true}>Add new place</button>
  </div>
);

export default PlaceList;
