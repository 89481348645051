import React from 'react';
import './App.css';
import * as Constants from './constants';

import Signup from './components/Signup';
import Login from './components/Login';
import Me from './components/Me';
import ApartmentList from './components/ApartmentList';
import PlaceList from './components/PlaceList';
import PlaceCleaningList from './components/PlaceCleaningList';

const App = () => {
  const tokenPresent = localStorage.getItem(Constants.userTokenLSKey) !== null;

  return (
    <div className="App">

      {(tokenPresent) && 
        <div>
          <Me />
          <ApartmentList />
          <PlaceList />
          <PlaceCleaningList />
        </div>
      }

      {(!tokenPresent) &&
        <div>
          <Signup />
          <Login />
        </div>
      }

    </div>
  );
};

export default App;
