import * as React from 'react';
import { SignupMutation, SignupMutationFn } from '../../generated/graphql';
import './styles.css';

interface Props {
  signupFn: SignupMutationFn;
  data?: SignupMutation;
}

const className = 'Signup';

const Signup: React.FC<Props> = ({ signupFn, data }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState("");

  function checkIfFormValid () {
    return(email !== "" && password !== "" && name !== "");
  }

  return (
    <div className={className}>
      <h3>Signup</h3>
      <form onSubmit={e => {
        e.preventDefault();
        signupFn({ variables: {
            email: email,
            password: password,
            name: name,
          }
        });
      }}>
        <input className={`${className}`} type="text" id="email" placeholder="Your email address" onChange={e => {
          setEmail(e.target.value);
        }}></input>

        <input className={`${className}`} type="password" id="password" placeholder="Your password" onChange={e => {
          setPassword(e.target.value);
        }}></input>

        <input className={`${className}`} type="text" id="name" placeholder="Your name" onChange={e => {
          setName(e.target.value);
        }}></input>

        <input type="submit" disabled={!checkIfFormValid()}></input>
      </form>
    </div>
  )
};

export default Signup;
