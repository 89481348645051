import * as React from 'react';
import { usePlaceCleaningListQuery, useCreatePlaceCleaningMutation } from '../../generated/graphql';
import PlaceCleaningList from './PlaceCleaningList';

const PlaceCleaningListContainer = () => {
  const placeCleaningListQueryInfo = usePlaceCleaningListQuery();
  const [createPlaceCleaningFn, createPlaceCleaningMutationInfo] = useCreatePlaceCleaningMutation();

  if (placeCleaningListQueryInfo.loading) {
    return <div>Loading...</div>;
  }

  if (placeCleaningListQueryInfo.error || !placeCleaningListQueryInfo.data) {
    return <div>Failure: {String(placeCleaningListQueryInfo.error)}</div>;
  }

  return <PlaceCleaningList  data={placeCleaningListQueryInfo.data} createPlaceCleaningInfo={createPlaceCleaningMutationInfo} createPlaceCleaningFn={createPlaceCleaningFn} />;
};

export default PlaceCleaningListContainer;
