import * as React from 'react';
import { PlaceCleaningListQuery, CreatePlaceCleaningMutationResult, CreatePlaceCleaningMutationFn } from '../../generated/graphql';
import './styles.css';

interface Props {
  data: PlaceCleaningListQuery;
  createPlaceCleaningInfo: CreatePlaceCleaningMutationResult;
  createPlaceCleaningFn: CreatePlaceCleaningMutationFn;
}

const className = 'PlaceCleaningList';

const PlaceCleaningList: React.FC<Props> = ({ data, createPlaceCleaningInfo, createPlaceCleaningFn }) => (
  <div className={className}>
    <h3>My Place Cleanings</h3>
    <ul className={`${className}__list`}>
      {!!data.placeCleanings &&
        data.placeCleanings.map(
          (placeCleaning, i) =>
            !!placeCleaning && (
              <li key={i} className={`${className}__item`}>
                {placeCleaning.id} at {placeCleaning.cleanedAt} in {String(placeCleaning.place.id)}
              </li>
            ),
        )}
    </ul>
    <button onClick={event => {createPlaceCleaningFn({
      variables: {
        placeId: "ck6mlzipp13ze0975sgv4mpg6", // TODO
        cleanedAt: new Date(),
      }})}}>Add new place cleaning to PLACE X</button>
    <div>
      {!!createPlaceCleaningInfo.data && 
        "Place cleaning created at " + createPlaceCleaningInfo.data.createPlaceCleaning.cleanedAt + " with following id: " + createPlaceCleaningInfo.data.createPlaceCleaning.id
      }
    </div>
  </div>
);

export default PlaceCleaningList;
